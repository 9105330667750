<template>
  <div>
    <!-- Authors Table -->
    <a-row :gutter="24" type="flex">

      <!-- Authors Table Column -->
      <a-col :span="24" class="mb-24">

        <a-card :bordered="true" class="header-solid h-full" style="min-height: 400px" v-if="!loading" :loading="loading">
          <a-form-item style="margin-bottom: 5px">
            <div class="ant-page-header-heading-title">{{ selectedItem.status=='published'||selectedItem.status=='Published'?'Event Summary':'Publish Your Event' }}</div>
            <router-link :to="{name:'Edit Event | Basic Info',params:{id:selectedItem._id}}">
              <a-button
                  @click="                                $emit('changeTab','1')
"
                  size="small" style="float: right" type="primary" >
                Edit
              </a-button>
            </router-link>
            <a-button :disabled="selectedItem&&selectedItem.dateType=='recurring'&&!selectedItem.schedules.length" v-if="selectedItem.status=='draft'||selectedItem.status=='Draft'"
                      size="small" style="float: right;margin-right:20px" type="primary" @click="publishPost()" :loading="confirmLoading">
              Publish Event
            </a-button>
            <a-dropdown>
              <a-menu slot="overlay" @click="handleMenuClick">
                <a-menu-item key="11" v-if="selectedItem.status=='draft'||selectedItem.status=='Draft'"> Mark As Deleted </a-menu-item>
                <a-menu-item key="22" v-if="selectedItem.status=='published'||selectedItem.status=='Published'"> Make Inactive </a-menu-item>
                <a-menu-item key="33" v-if="selectedItem.status=='deleted'||selectedItem.status=='Deleted'"> Mark As Draft </a-menu-item>
                <a-menu-item key="44" v-if="selectedItem.status=='inactive'||selectedItem.status=='Inactive'"> Publish Video </a-menu-item>
              </a-menu>
              <a-tag class="tag-status ant-tag-primary" style="cursor:pointer;margin-left: 8px;float: right;margin-right:20px;font-size:15px;padding: 7px 15px;" :class="{'ant-tag-success':selectedItem.status=='published'||selectedItem.status=='Published','ant-tag-danger':selectedItem.status=='deleted'||selectedItem.status=='Deleted','ant-tag-warning':selectedItem.status=='inactive'||selectedItem.status=='Inactive'}">
                {{ selectedItem?selectedItem.status:'' | capitalizeFirstLetter }} <a-icon type="down" style="color:white"/> </a-tag>
            </a-dropdown>
          </a-form-item>
          <a-row v-if="selectedItem&&!selectedItem.schedules.length" style="margin-top: 15px;margin-bottom: 8px;" :gutter="24" type="flex">
            <a-col :span="24">
              <a-alert type="error" show-icon>
              <span slot="message">
              No schedule has been added yet. Please add at least one schedule(date/time and location) on <router-link to="schedules"><span v-on:click="$emit('changeTab','6')">Schedules</span></router-link> tab in order to publish the event.
              </span>
              </a-alert>
            </a-col>
          </a-row>
          <a-row v-if="selectedItem&&!selectedItem.description" style="margin-top: 15px;margin-bottom: 8px;" :gutter="24" type="flex">
            <a-col :span="24">
              <a-alert  type="error" show-icon >
              <span slot="message">
                No description about the event has been added yet. Please add around 200 words on <router-link to="descriptions"><span v-on:click="$emit('changeTab','2')">Description</span></router-link> tab in order to publish the event.
              </span>
              </a-alert>
            </a-col>
          </a-row>
          <ul class="ant-list-items">
            <li class="ant-list-item" style="justify-content: unset">
              <div class="ant-list-item-extra">
                <img alt="logo"
                     :src="selectedItem.image?(selectedItem.image.type=='image_source'?selectedItem.image.sourceUrl:url+selectedItem.image.uploadPath):'images/no-image.jpg'"
                     style="height: auto;width:auto;max-height: 300px;max-width:300px;border-radius: 5px"></div>
              <div class="ant-list-item-main" style="padding: 15px 15px 15px 25px">
                <div class="ant-list-item-meta">
                  <div class="ant-list-item-meta-content">
                    <h4 class="ant-list-item-meta-title text-primary">
                      <div style="display: flex;font-size: 16px"> {{selectedItem.title}}
                      </div>
                    </h4>
                  </div>
                </div>

                <div class="table-avatar-info" style="align-items: center;margin-top: 6px;">
                  <a-avatar shape="square" :src="selectedItem?url+selectedItem.collectionId.image:''" style="margin-right: 8px;"></a-avatar>
                  <div class="avatar-info">
                    <h6>{{ selectedItem?selectedItem.collectionId.title:'' }}</h6>
                  </div>

<!--                  <span style="margin:0 5px 3px 20px" v-if="selectedItem.dateType=='single'">-->
<!--                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>-->
<!--                    &lt;!&ndash;                <a-icon type="global" style="margin:0 5px 3px 20px" />&ndash;&gt;-->
<!--                </span>-->
                  <!--                  <div class="avatar-info" v-if="selectedItem.locationType=='physical'">-->
                  <!--                  <h6>{{selectedItem.singleSchedule.post_code + ', ' + selectedItem.singleSchedule.district + ', ' + selectedItem.singleSchedule.country-->
                  <!--                    }}</h6>-->

                  <!--                </div>-->
<!--                  <div class="avatar-info" v-if="selectedItem.dateType=='single'">-->
<!--                    <h6>{{selectedItem.singleSchedule.locationType=='physical'?(selectedItem.singleSchedule.post_code + ', ' + selectedItem.singleSchedule.district + ', ' + selectedItem.singleSchedule.country):(selectedItem.singleSchedule.locationType=='online'?'Online Event':'Not Announced Yet')-->
<!--                      }}</h6>-->

<!--                  </div>-->

                  <span v-if="selectedItem&&!selectedItem.schedules.length" style="margin-left:20px">
                    <a-tag size="small" color="red">
                      Schedules not added
                    </a-tag>
                  </span>
                </div>
<!--                <div style="color: rgba(0, 0, 0, 0.45);-->
<!--    font-size: 15px;font-weight: 600;-->
<!--    margin-top: 0.7rem;"-->
<!--                   v-if="selectedItem.dateType=='single'"-->
<!--                >-->
<!--                  <a-icon type="calendar" style="margin-right: 8px" />-->
<!--                  <span v-if="new Date(selectedItem.singleSchedule.startDate).toDateString()!=new Date(selectedItem.singleSchedule.endDate).toDateString()">{{ selectedItem.singleSchedule.startDate | moment("D MMM YYYY, hh:mm A") }} - {{ selectedItem.singleSchedule.endDate | moment("D MMM YYYY, hh:mm A") }}</span>-->
<!--                  <span v-else>{{ selectedItem.singleSchedule.startDate | moment("D MMM YYYY, hh:mm A") }} - {{ selectedItem.singleSchedule.endDate | moment("hh:mm A") }}</span>-->
<!--                </div>-->
                <div style="color: rgba(0, 0, 0, 0.45);
    font-size: 15px;font-weight: 600;" v-if="selectedItem&&selectedItem.schedules.length">
              <div v-if="selectedItem.schedules&&selectedItem.schedules.length" style="margin-right:20px;margin-top: 0.7rem;">
                <h6>Schedules
                  <span v-if="selectedItem&&selectedItem.schedules.length>1" style="margin-left:10px">
                  <a-tag size="small" color="blue">
                    Multiple Schedules
                  </a-tag>
               </span>
                  <span v-if="selectedItem&&selectedItem.schedules.length==1" style="margin-left:10px">
                  <a-tag size="small" color="blue">
                    Single Schedule
                  </a-tag>
               </span></h6>
                <div :key="schedule._id" v-for="schedule in selectedItem.schedules">
                <a-icon type="calendar" style="margin-right: 8px" />
                <span v-if="new Date(schedule.startDate).toDateString()!=new Date(schedule.endDate).toDateString()">{{ schedule.startDate | moment("D MMM YYYY, hh:mm A") }} - {{ schedule.endDate | moment("D MMM YYYY, hh:mm A") }}</span>
                <span v-else>{{ schedule.startDate | moment("D MMM YYYY, hh:mm A") }} - {{ schedule.endDate | moment("hh:mm A") }}</span>
                <span> |
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                {{schedule.locationType=='physical'?(schedule.post_code + ', ' + schedule.district + ', ' + schedule.country):(schedule.locationType=='online'?'Online Event':'Not Announced Yet')
                    }}</span>
              </div>
              </div>
<!--                  <div v-else style="margin-top: 0.7rem;">-->
<!--                   <a-tag size="small" color="red">-->
<!--                  Schedules not added-->
<!--                </a-tag>-->
<!--                </div>-->
                </div>

                <div style="color: rgba(0, 0, 0, 0.45); font-size: 14px; font-weight: 600; margin: 0.5rem 0px 0.5rem 0;">
                  <a-avatar style="margin-right: 0px" v-if="selectedItem&&selectedItem.user.imageUrl" slot="avatar"
                            :src="selectedItem.user.imageUrl"/>
                  <a-avatar v-else slot="avatar" style="background-color: #1890ff;margin-right: 0px">
                    {{ (selectedItem ? selectedItem.user.name : '')|getNameForAvatar }}
                  </a-avatar>
                  Created by {{ selectedItem?selectedItem.user.name:'' }} on {{ selectedItem.createdAt | moment("MMM D YYYY, hh:mm A") }}
                </div>
                <ul class="ant-list-item-action" style="margin-left: 0">
                  <li> {{ selectedItem.albumsCount?selectedItem.albumsCount:'No' }} {{ selectedItem.albumsCount==1?'Album':'Albums' }} <em class="ant-list-item-action-split"></em></li>
                  <li> {{ selectedItem.videosCount?selectedItem.videosCount:'No' }} {{ selectedItem.videosCount==1?'Video Clip':'Video Clips' }} <em class="ant-list-item-action-split"></em></li>
                  <li v-if="selectedItem&&$options.filters.countTickets(selectedItem)"> {{selectedItem | countTickets}} Tickets &nbsp;&nbsp;<router-link to="schedules"><span v-on:click="$emit('changeTab','6')">View</span></router-link><em class="ant-list-item-action-split"></em></li>
                  <li v-else style="color: #f5222d"> No Tickets&nbsp;&nbsp;<router-link to="schedules"><span v-on:click="$emit('changeTab','6')">Add Now</span></router-link><em class="ant-list-item-action-split"></em></li>
                  <li>Modified {{ moment(selectedItem.updatedAt).fromNow() }}
                  </li>
                </ul>
              </div>

            </li>
            <li class="ant-list-item" style="justify-content: unset" >
              <a-form-item style="margin-bottom: 0">
                <h4 class="ant-page-header-heading-title">Event Image Source Platform</h4>
                <div>
                  {{selectedItem&&selectedItem.image&&selectedItem.image.sourceName?selectedItem.image.sourceName:'N/A' }}
                </div>
              </a-form-item>

            </li>
            <li class="ant-list-item" style="justify-content: unset" >
              <div>
                <a-form-item style="margin-bottom: 15px">
                  <h4 class="ant-page-header-heading-title">Organizer</h4>
                </a-form-item>
                <span v-if="selectedItem.organizer">
                   <div class="table-avatar-info" style="align-items: center">
                     <a-avatar style="margin-right: 10px" v-if="selectedItem&&selectedItem.organizer&&selectedItem.organizer.image" slot="avatar"
          :src="url+selectedItem.organizer.image"/>
                  <a-avatar v-else slot="avatar" style="background-color: #1890ff;margin-right: 10px">
                    {{ (selectedItem&&selectedItem.organizer ? selectedItem.organizer.title : '')|getNameForAvatar }}
                  </a-avatar>
                     <div class="avatar-info">
                  <h6>{{selectedItem?selectedItem.organizer.title:''}}</h6>
                   <a target="_blank" :href="selectedItem.organizer.siteUrl" style="font-size: 11px">{{
                       selectedItem.organizer.siteUrl
                     }}</a>
                </div>
              </div>

<!--                  {{selectedItem?selectedItem.organizer.title:''}}-->
<!--                  <br>-->
<!--                <a target="_blank" :href="selectedItem.organizer.siteUrl" style="font-size: 11px">{{-->
<!--                    selectedItem.organizer.siteUrl-->
<!--                  }}</a>-->
                </span>
                <span v-else style="color: #f5222d"> No organizer selected</span>
              </div>
            </li>
            <li class="ant-list-item" style="justify-content: unset" >
              <div>
                <a-form-item style="margin-bottom: 15px">
                  <h4 class="ant-page-header-heading-title">Tags</h4>
                </a-form-item>
                  <div style="display: flex" v-if="selectedItem.tags&&selectedItem.tags.length">
                    <a-tag color="cyan" :key="index" v-for="(tag,index) in selectedItem.tags">
                      {{ tag }}
                    </a-tag>
                  </div>
                <div v-else style="color: #f5222d">Tags not added</div>

              </div>
            </li>
            <li class="ant-list-item" style="justify-content: unset">
              <div>
                <a-form-item style="margin-bottom: 15px">
                  <h4 class="ant-page-header-heading-title">Description</h4>
                </a-form-item>
                  <div v-if="selectedItem.description" v-html="selectedItem.description">
                  </div>
                <div v-else style="color: #f5222d">Description not added</div>
              </div>
            </li>
<!--            <li class="ant-list-item" style="justify-content: unset">-->
<!--              <div>-->
<!--                <a-form-item style="margin-bottom: 15px">-->
<!--                  <h4 class="ant-page-header-heading-title">Image Carousel</h4>-->
<!--                </a-form-item>-->
<!--                <div v-if="selectedItem.resources&&selectedItem.resources.length">-->
<!--                <a-form-item>-->
<!--                  <h6 class="ant-page-header-heading-title">{{ selectedItem.resourceTitle }}</h6>-->
<!--                </a-form-item>-->
<!--                  <div style="margin-top: 20px;" v-if="selectedItem&&selectedItem.resources.length">-->
<!--                    <a-carousel arrows dots-class="slick-dots slick-thumb">-->
<!--                      <div-->
<!--                          slot="prevArrow"-->
<!--                          slot-scope="props"-->
<!--                          class="custom-slick-arrow"-->
<!--                          style="left: 10px;zIndex: 1"-->
<!--                      >-->
<!--                        <a-icon type="left-circle" />-->
<!--                      </div>-->
<!--                      <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">-->
<!--                        <a-icon type="right-circle" />-->
<!--                      </div>-->
<!--                      <a slot="customPaging" slot-scope="props">-->
<!--                        <img :src="selectedItem.resources[props.i].type=='image_source'?selectedItem.resources[props.i].link:url+selectedItem.resources[props.i].uploadPath" />-->
<!--                      </a>-->
<!--                      <div  v-for="item in selectedItem.resources">-->
<!--                        <img width="100%" height="300" style="object-fit: contain;max-width: 100%;" :src="item.type=='image_source'?item.link:url+item.uploadPath" />-->
<!--                      </div>-->
<!--                    </a-carousel>-->
<!--                    <br>-->
<!--                    <br>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div v-else style="color: #f5222d">No carousel added</div>-->
<!--              </div>-->
<!--            </li>-->
          </ul>

          <a-form-item style="top: 15px">
                              <h4 class="ant-page-header-heading-title">Image Carousel</h4>
                            </a-form-item>
          <div style="margin-top: 20px;" v-if="selectedItem&&selectedItem.resources.length">
            <a-carousel  arrows dots-class="slick-dots slick-thumb">
              <div
                  slot="prevArrow"
                  slot-scope="props"
                  class="custom-slick-arrow"
                  style="left: 10px;zIndex: 1"
              >
                <a-icon type="left-circle" />
              </div>
              <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">
                <a-icon type="right-circle" />
              </div>
              <a slot="customPaging" slot-scope="props">
                <img :src="selectedItem.resources[props.i].type=='image_source'?selectedItem.resources[props.i].link:url+selectedItem.resources[props.i].uploadPath" />
              </a>
              <div  v-for="item in selectedItem.resources">
                <img width="100%" height="300" style="object-fit: contain;max-width: 100%;" :src="item.type=='image_source'?item.link:url+item.uploadPath" />
              </div>
            </a-carousel>
            <br>
            <br>
            <br>

          </div>
          <div v-else style="color: #f5222d">Carousel not added</div>


        </a-card>

        <a-card :bordered="true" class="header-solid h-full" style="min-height: 400px" v-else>
          <a-row :gutter="[24, 24]">
            <a-col :span="24" >
              <a-skeleton active />
              <a-skeleton avatar  active />
              <a-skeleton active />
              <a-skeleton active />
            </a-col>
          </a-row>
        </a-card>
      </a-col>

    </a-row>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  data() {
    return {
      moment,
      url: process.env.VUE_APP_SERVER_URL,
      confirmLoading: false,
      loading: false,
      form: this.$form.createForm(this),


    };
  },
  computed: {
    footerBtnTitle() {
      return this.editMode ? 'Update Event' : 'Create Event'
    },
    selectedItem() {
      return this.$store.getters.selectedEvent
    },
  },
  mounted() {
    this.eventInfo()
  },
  methods: {
    eventInfo() {
      this.loading = true
      this.$store.dispatch("FETCH_EVENT", {id: this.$route.params.id})
          .finally(() => {
            this.loading = false
          })
    },
    submitForm(e) {
      e.preventDefault();
      if (this.confirmLoading)
        return
      this.form.validateFields((err, values) => {
        if (!err) {
          this.confirmLoading = true
          this.$store.dispatch("UPDATE_EVENT", {id: this.id, form: formData})
              .then((res) => {
                this.$message.success(res.data.message);
                this.$store.commit('CHANGE_DATE_TYPE', values.dateType)
                if (values.dateType == 'recurring') {
                  this.$emit('changeTab', '6')
                } else
                  this.$emit('changeTab', '2')
              })
              .catch((err) => {
                // this.$message.error(err.response.data.message);
              })
              .finally(() => {
                this.confirmLoading = false
              });
        }
      });
    },
    handleMenuClick(key){
      this.$confirm({
        title: 'Are you sure want to update the event?',
        content: 'Are you sure want to continue?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: () => {
          let status=this.selectedItem.status
          if (key.key=='11')
            status='deleted'
          else if (key.key=='22')
            status='inactive'
          else if (key.key=='33')
            status='draft'
          else if (key.key=='44')
            status='published'

          this.$store.dispatch("UPDATE_EVENT_STATUS", {status:status,id:this.selectedItem._id})
              .then((res) => {
                this.$message.success(res.data.message);
              })
              .catch((err) => {
                // this.$message.error(err.response.data.message);
              })
              .finally(() => {
                // this.confirmLoading = false
              });
        },
      });

    },
    publishPost(  ){
      this.$confirm({
        title: 'Are you sure want to publish the event?',
        content: 'Are you sure want to continue?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: () => {
            let status='published'
          this.confirmLoading = true
          this.$store.dispatch("UPDATE_EVENT_STATUS", {status:status,id:this.selectedItem._id})
              .then((res) => {
                this.$message.success(res.data.message);
              })
              .catch((err) => {
                // this.$message.error(err.response.data.message);
              })
              .finally(() => {
                this.confirmLoading = false
              });
        },
      });

    },
  }
}
</script>
<style scoped>
/* For demo */
.ant-carousel >>> .slick-dots {
  height: auto;
}
.ant-carousel >>> .slick-slide img {
  border: 5px solid #fff;
  display: block;
  margin: auto;
  max-width: 80%;
}
.ant-carousel >>> .slick-thumb {
  bottom: -45px;
}
.ant-carousel >>> .slick-thumb li {
  width: 60px;
  height: 45px;
}
.ant-carousel >>> .slick-thumb li img {
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
}
.ant-carousel >>> .slick-thumb li.slick-active img {
  filter: grayscale(0%);
}

</style>